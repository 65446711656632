
import {computed, defineComponent, ref} from "vue";
import Entities from "@/components/base/common/Entities.vue";
import {Actions} from "@/store/enums/StoreEnums";
import {LoadFilterObjects} from "@/core/composite/composite";
import store from "@/store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import UserLink from "@/views/user/UserLink.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";

export default defineComponent({
  name: "LoginAudits",
  components: {HelpEmbed, InfoBox, UserLink, DateTimeFormat, KtDatatable, Entities},
  props: {
    companyId: {type: String}
  },
  setup(props) {
    const filterObject = ref({isCompany: true, teamId: props.companyId})
    const tableHeader = ref([
      {
        name: "Time",
        key: "time",
        sortable: false,
      },
      {
        name: "User",
        key: "user",
        sortable: false,
      },
      {
        name: "IP",
        key: "ip",
        sortable: false,
      },
      {
        name: "Location",
        key: "location",
        sortable: false,
      },
      {
        name: "User Agent",
        key: "userAgent",
        sortable: false,
      }
      // {
      //   name: "Device",
      //   key: "device",
      //   sortable: false,
      // },
      // {
      //   name: "Latitude",
      //   key: "latitude",
      //   sortable: false,
      // },
      // {
      //   name: "Longitude",
      //   key: "longitude",
      //   sortable: false,
      // },
      // {
      //   name: "Longitude",
      //   key: "longitude",
      //   sortable: false,
      // },
      // {
      //   name: "Access able",
      //   key: "accessAble",
      //   sortable: false,
      // },
    ]);
    const loginPage = computed(() => store.state.LoginModule.loginPage);
    return {
      ...LoadFilterObjects(Actions.LOAD_LOGIN_AUDITS, filterObject.value, ['user']),
      tableHeader,
      loginPage,
    }
  }
})
